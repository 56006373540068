import React from "react"

function ServiceCta({ ctaButtonText, ctaIntroText, ctaUnderButtonText, ctaLink}){
return (
  <section className="content cta-background service-cta-block finish-cta-holder">
   <div className="eighty-spacer"></div>
   <div className="content-block">

      <div className="one-flex-container">
         <div className="half-flex-container">
              <div className="page-intro-half-container staggerFadeInFirst first-load" dangerouslySetInnerHTML={{ __html: ctaIntroText }}/>
         </div>
         <div className="half-flex-container">
            <div className="sign-post-half-container">
               <div className="staggerFadeInFirst first-load">
                              <ul>
                  <li>
                     <a href={ctaLink}>{ctaButtonText}</a>
                  </li>
               </ul>
                              </div>
                              <div className="staggerFadeInFirst second-intro first-load" dangerouslySetInnerHTML={{ __html: ctaUnderButtonText }}/>
            </div>
         </div>
      </div>

   </div>
   <div className="eighty-spacer"></div>
</section>
 )
  }


export default ServiceCta
